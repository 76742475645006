<template>
  <div class="page">
    <!--<el-carousel indicator-position="outside" :height="casHeight" :interval="5000">-->
    <!--<el-carousel-item v-for="(item,index) in picList" :key="index">-->
    <!--<el-image-->
    <!--:src="item.src"-->
    <!--style="width: 100%; height: 100%"-->
    <!--&gt;</el-image>-->
    <!--</el-carousel-item>-->
    <!--</el-carousel>-->
    <img src="../../assets/images/caseBG.png" class="backgroundPic">
    <div class="flexBoxCol">
      <div class="p48-400 mtp50 mbm50">选择斯点的理由</div>
      <div class="homeIconLine">
        <div class="homeIconBox">
          <svg-icon icon-class="zcfc"></svg-icon>
          <p class="p36">政策扶持</p>
          <span class="p20">国家鼓励学校信息化建设</span>
          <span class="p20">多次出台相关支持文件</span>
          <span class="p20">学校配合响应积极性高</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="scqj"></svg-icon>
          <p class="p36">市场前景</p>
          <span class="p20">全国中小学数量超30W所</span>
          <span class="p20">智慧校园普及程度不足5%</span>
          <span class="p20">市场前景好</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="gxjb"></svg-icon>
          <p class="p36">高性价比</p>
          <span class="p20">价格经济合理</span>
          <span class="p20">品质服务保证，校方接受度高</span>
          <span class="p20">容易推进市场</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="cxsy"></svg-icon>
          <p class="p36">持续收益</p>
          <span class="p20">特色付费模式得到市场验证</span>
          <span class="p20">每年2/3固定比例稳定创收</span>
          <span class="p20" style="opacity: 0">占位</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="wxyd"></svg-icon>
          <p class="p36">无需压贷</p>
          <span class="p20">0成本投入，轻模式运营</span>
          <span class="p20">不设定压货目标</span>
          <span class="p20">最大程度减轻资金压力</span>
        </div>
      </div>
    </div>
    <div class="flexBoxCol mtp50">
      <div class="p48-400 mtp50 mbm50">总部支持</div>
      <div class="homeIconLine">
        <div class="homeIconBox">
          <svg-icon icon-class="qybh"></svg-icon>
          <p class="p36">区域保护</p>
          <span class="p20">提供学校报备机制</span>
          <span class="p20">避免恶性竞争</span>
          <span class="p20">保障合作伙伴权益</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="pptg"></svg-icon>
          <p class="p36">品牌推广</p>
          <span class="p20">提供展会、网络等</span>
          <span class="p20">多渠道方式推广</span>
          <span class="p20">扩大品牌知名度</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="pxfw"></svg-icon>
          <p class="p36">培训服务</p>
          <span class="p20">提供商务谈判分享</span>
          <span class="p20">产品介绍、成功运营案例</span>
          <span class="p20">后台管理操作等培训</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="jszc"></svg-icon>
          <p class="p36">技术支持</p>
          <span class="p20">团队依托全球领先的AI技术</span>
          <span class="p20">保障产品稳定可靠</span>
          <span class="p20" style="opacity: 0">占位</span>
        </div>
      </div>
      <div class="homeIconLine">
        <div class="homeIconBox">
          <svg-icon icon-class="cpsj"></svg-icon>
          <p class="p36">产品升级</p>
          <span class="p20">自主研发团队</span>
          <span class="p20">不断更新打磨产品</span>
          <span class="p20">满足多样的用户需求</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="yyzc"></svg-icon>
          <p class="p36">运营支持</p>
          <span class="p20">打造全新产品功能</span>
          <span class="p20">激励家长持续使用功能</span>
          <span class="p20">增加整体收益</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="xmsg"></svg-icon>
          <p class="p36">项目施工</p>
          <span class="p20">提供技术人员落地</span>
          <span class="p20">配合合伙人完成施工部署</span>
          <span class="p20">后续远程提供支持</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="shfw"></svg-icon>
          <p class="p36">售后服务</p>
          <span class="p20">家长使用问题</span>
          <span class="p20">均由总部团队进行维护</span>
          <span class="p20">合伙人仅需维护校方关系</span>
        </div>
      </div>
    </div>
    <div class="flexBoxCol mtp50">
      <div class="p48-400 mtp50">合作伙伴</div>
      <div class="homeIconLine">
        <div class="homeIconBox" style="width: 30%">
          <svg-icon icon-class="liantong" style="width: 15em;height: 6em;"></svg-icon>
        </div>
        <div class="homeIconBox" style="width: 30%">
          <svg-icon icon-class="yidong" style="width: 15em;height: 6em;"></svg-icon>
        </div>
        <div class="homeIconBox" style="width: 30%">
          <svg-icon icon-class="dianxin" style="width: 15em;height: 6em;"></svg-icon>
        </div>
      </div>
    </div>
    <div class="flexBoxCol mtp50">
      <div class="p48-400 mtp50">联系我们</div>
      <div class="homeIconLine">
        <div class="homeIconBox" style="width: 30%">
          <div style="display: flex;flex-direction: column;align-items: center;">
            <span class="p20">看的再多，不如扫码洽谈</span>
            <span class="p20">斯点，必会让你满意而归</span>
          </div>
          <img src="../../assets/images/contactUs.png" style="width: 300px;height: 300px;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      picList: [
        {
          src: require('../../assets/images/home.png')
        }
      ]
    }
  }
}
</script>
<style scoped lang="less">
  .homeIconLine {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
  }
  .homeIconBox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
  }
</style>
